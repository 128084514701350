import { Box } from "@mui/material"

const BoxContainer = ({ children }) => {

    return (
        <Box
            width={'100%'}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
                marginTop: "30px",

            }}>
            {children}
        </Box>

    )
}
export default BoxContainer