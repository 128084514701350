import { Box, Grid, ListItemText, Menu, MenuItem, Typography, Zoom } from '@mui/material'
import { ReactComponent as Logo } from '../../../../../../../../assets/logo-simple.svg'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { isValidCoordinate } from '../../../../../../../../utils/helpers/validator'
import { GOOGLE_API_KEY } from '../../../../../../../../utils/config'
import "./MenuToggle.css";

const ExploreHeader = ({ backgroundColor }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const [openMenu, setOpenMenu] = useState(false)
  const [address, setAddress] = useState('');

  useEffect(() => {
    const fetchGeolocation = async () => {
      if (!navigator.geolocation) {
        return;
      }

      const geoSuccess = async (position) => {
        const { latitude, longitude } = position.coords;

        if (isValidCoordinate(latitude) && isValidCoordinate(longitude)) {

          try {
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_API_KEY}`
            );
            const data = await response.json();

            if (data.status === 'OK' && data.results.length > 0) {
              setAddress(data.results[0].formatted_address);
            } else {
              setAddress('Unknown location');
            }
          } catch (error) {
            console.error('Error fetching address:', error);
            setAddress('Unknown location');
          }
        } else {
          console.error('Invalid geolocation coordinates:', { latitude, longitude });
        }
      };

      const geoErrorHandler = (error) => {
        console.error('Error obtaining location:', error);
      };

      navigator.geolocation.getCurrentPosition(geoSuccess, geoErrorHandler);
    };

    fetchGeolocation();
  }, [setAddress]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpenMenu(true)
  }
  const handleCloseMenu = () => {
    setOpenMenu(false)
    setAnchorEl(null)
  }

  return (
    <Box
      sx={{
        paddingTop: '10px',
        backgroundColor: backgroundColor ? backgroundColor : '#f9f9f9',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px',
          width: '100%',
          marginBottom: '40px',
          gap: 2
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '80%' }}>
          <Logo height={60} width={60} style={{ cursor: "pointer" }} onClick={() => navigate('/')} />
          {
            address && <>
              <FmdGoodIcon style={{ marginLeft: '10px', marginRight: '10px' }} />
              <Typography>{address}</Typography></>
          }

        </Box>

        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 3,
            width: 'auto',
            padding: '0 16px',
            flexWrap: 'nowrap',
            overflowX: 'auto'
          }}
        >
          <AccountCircleIcon fontSize='large' sx={{ fontSize: { xs: 30, sm: 30, md: 40 }, mr: -2 }} />
          <input 
            type="checkbox"
            role="button"
            aria-label="Display the menu"
            class="menu"
            checked={openMenu}
            onClick={(event) => handleClick(event)}
          />

          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            MenuListProps={{
              autoFocusItem: false,
            }}
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 400 }}
            sx={{
              '& .MuiMenu-root': {
                display: 'block',
                zIndex: 1300,
              },
              '& .MuiMenuItem-root': {
                padding: { xs: '5px 10px', sm: '10px 20px', md: '15px 30px' },
                fontSize: { xs: '0.8rem', sm: '1rem', md: '1.2rem' },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                navigate('/login');
                handleCloseMenu();
              }}
              sx={{ padding: { xs: '5px 10px', sm: '10px 20px', md: '15px 30px' } }}
            >
              <ListItemText>
                {t('button.login')}
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate('/signup');
                handleCloseMenu();
              }}
              sx={{ padding: { xs: '5px 10px', sm: '10px 20px', md: '15px 30px' } }}
            >
              <ListItemText>
                {t('button.signup')}
              </ListItemText>
            </MenuItem>
          </Menu>
        </Grid>
      </Box>
    </Box>
  )
}

export default ExploreHeader;
