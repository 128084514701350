import { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import TrainerCard from './subcomponet/TrainerCard'
import AllTrainers from './subcomponet/AllTrainer'
import CustomSlider from '../../../../../common/CustomSlider'
import useTrainers from '../../../../../../hooks/useTrainers'
import { useExplore } from '../../../../../../context/explore-context'
import BoxElements from '../../../../../../utils/boxElementsV2'

const TrainerSlider = ({ currentPosition }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const itemsPerRow = isMobile ? 2 : 6
  const [showAll, setShowAll] = useState(false)
  const { totalCount, trainers, getTrainers } = useTrainers()
  const { selectedActivities, selectedRange, searchQuery } = useExplore()
  const trainerRef = useRef(null)
  const fetchTrainers = useCallback(
    async (targetIndex) => {
      const newLimit = (targetIndex + 1) * itemsPerRow + itemsPerRow
      await getTrainers(
        0,
        newLimit,
        selectedActivities,
        selectedRange,
        currentPosition,
        searchQuery
      )
    },
    [getTrainers, itemsPerRow, selectedActivities, selectedRange, currentPosition, searchQuery]
  )

  useEffect(() => {
    if (showAll) return
    fetchTrainers(0)
  }, [fetchTrainers, showAll])

  const renderedTrainerCards = useMemo(() => {
    return trainers.map((trainer) => (
      <Box
        key={trainer.id}
        sx={{ marginRight: isMobile ? 2 : 5 }}
        padding={!isMobile && '0 0 10px 10px'}>
        <TrainerCard row={trainer} width={isMobile ? '170px' : '200px'} />
      </Box>
    ))
  }, [trainers])

  return (
    <Box
      width={'100%'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        marginTop: "30px",
        marginBottom: '30px'
      }}>
      {showAll && trainers.length > 0 ? (
        <AllTrainers isShowAll={showAll} setIsShowAll={setShowAll} trainers={trainers} />
      ) : (
        <BoxElements>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2, width: '100%' }}>
            <Typography
              ref={trainerRef}
              variant="h5"
              fontWeight="bold"
              sx={{
                ml: 1,
                fontSize: isMobile ? '1rem' : '1.3rem',

              }}
              id={'trainer-slider'}>
              Trainers
            </Typography>
            {trainers.length > 0 && (
              <Typography
                variant="h5"
                color="primary"
                sx={{
                  cursor: 'pointer', mr: 1,
                  fontSize: isMobile ? '1rem' : '1.3rem',
                }}
                onClick={() => setShowAll((prev) => !prev)}>
                Show All
              </Typography>
            )}
          </Box>

          {trainers.length > 0 ? (
            <CustomSlider totalCount={totalCount} itemsPerRow={itemsPerRow} fetch={fetchTrainers}>
              {renderedTrainerCards}
            </CustomSlider>
          ) : (
            <Typography sx={{ marginTop: isMobile ? '-40px' : '-50px', fontSize: isMobile ? '1rem' : '1.3rem', }} variant="h6" color="text.secondary">
              No trainers found
            </Typography>
          )}
        </BoxElements>

      )}
    </Box>

  )
}

export default TrainerSlider
