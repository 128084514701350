import { Box } from "@mui/material"

const ContainerV2 = ({ children }) => {
    return (
        <Box width={'100%'} paddingRight={'3%'} paddingLeft={'3%'}>
            {children}
        </Box>

    )
}
export default ContainerV2