import React, { useEffect, useRef, useState } from 'react'
import { Dialog, IconButton, Button, Typography, Grid, TextField, Avatar, CircularProgress, FormControl, Radio, RadioGroup, FormControlLabel } from '@mui/material'
import { Close, AddAPhoto, Edit } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import {
    GRAY_TEXT_COLOR
} from '../../../../utils/constants'
import { BUCKET_BASE_URL } from '../../../../utils/config'

import * as COMPANY_REQUESTS from '../../../../api/company'
import * as USER_REQUESTS from '../../../../api/user'
import * as DOCUMENTS_REQUESTS from '../../../../api/documents'
import * as USER_SUBSCRIPTIONS from '../../../../api/userSubscriptions'

const AddPerson = ({
    onCancel,
    onConfirm,
    user
}) => {
    const photoFileInputRef = useRef()
    const theme = useTheme()
    const matchesTablet = useMediaQuery(theme.breakpoints.down('md'))
    const { t } = useTranslation()

    const [data, setData] = useState({
        gender: 'MALE',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        bio: '',
        avatarPhotoSrc: ''
    })
    const [loading, setLoading] = useState(false)
    const [photoLoading, setPhotoLoading] = useState(false)

    const hasError = () => !data.firstName || !data.lastName || !data.phoneNumber || loading

    const onCreateHandler = () => {
        setLoading(true)
        const payload = {}

        Object.keys(data).forEach(key => {
            if (data[key]) {
                payload[key] = data[key]
            }
        })

        return (user ? COMPANY_REQUESTS.updateOwnUser(user.id, payload) : COMPANY_REQUESTS.createOwnUser(payload))
            .then(() => {
                return onConfirm()
            })
            .finally(() => {
                setLoading(false)
                onCancel()
            })
    }

    const onPhotoChangeHandler = (event) => {
        const file = event.target.files[0]

        const name = Date.now()
        const extension = file.type.split('/')[1]

        setPhotoLoading(true)

        DOCUMENTS_REQUESTS.generatePresignedUrl({ name, extension })
            .then((presignedUrl) => {
                return DOCUMENTS_REQUESTS.uploadDocumentS3(presignedUrl, file)
            })
            .then(() => {
                setData(prevData => ({ ...prevData, avatarPhotoSrc: `${BUCKET_BASE_URL}/${name}.${extension}` }))
                setPhotoLoading(false)
            })
            .catch(() => {
                setPhotoLoading(false)
            })
    }

    useEffect(() => {
        if (!user) return

        const newData = { ...data }

        Object.keys(user).forEach(key => {
            newData[key] = user[key]
        })

        setData(newData)
    }, [user])

    return (
        <>
            <input
                type="file"
                ref={photoFileInputRef}
                onChangeCapture={onPhotoChangeHandler}
                style={{ display: 'none' }}
            />
            <Dialog open maxWidth="sm" fullScreen={matchesTablet} fullWidth PaperProps={{ ...(matchesTablet ? { sx: { borderRadius: 0 } } : {}) }}>
                <Grid container p={3}>
                    <Grid item xs={12}>
                        <Grid container display="flex" flexDirection="row">
                            <Grid item
                                xs={10}
                                md={10}
                                lg={10}
                                pr={{ xs: 0, md: 0, lg: 2 }}
                                pl={{ xs: 0, md: 0, lg: 2 }}>
                                <Typography variant="h4" fontWeight={500} color={'black'}>
                                    {user ? t('clients.addPersonDialog.titleEdit'): t('clients.addPersonDialog.titleCreate')}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                                <IconButton onClick={onCancel}>
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        pt={1}
                        pb={4}
                        xs={12}
                        display="flex"
                        flexDirection="column"
                        pr={{ xs: 0, md: 0, lg: 2 }}
                        pl={{ xs: 0, md: 0, lg: 2 }}
                    >
                        <Grid item xs={12}>
                            <Typography variant="p" fontWeight={500} color={GRAY_TEXT_COLOR}>
                                {t('clients.addPersonDialog.description')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} pt={2}>
                            <Grid container>
                                <Grid item xs={12} pt={2} pb={2} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                                    <Avatar sx={{ width: 120, height: 120 }} src={data.avatarPhotoSrc}>{
                                        photoLoading ? <CircularProgress /> : <IconButton onClick={() => photoFileInputRef.current.click()}><AddAPhoto /></IconButton>
                                    }</Avatar>
                                    <Button type="text" onClick={() => photoFileInputRef.current.click()}><Edit /> Photo</Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6} pr={0.5}>
                                    <Grid item xs={12} pb={0.5}>
                                        <Typography variant="p" fontWeight={500}>
                                            {t('clients.addPersonDialog.inputs.firstName')}*
                                        </Typography>
                                    </Grid>
                                    <TextField
                                        fullWidth
                                        value={data.firstName}
                                        onChange={event => setData(prevData => ({ ...prevData, firstName: event.target.value }))}
                                        placeholder="Marian"
                                    />
                                </Grid>
                                <Grid item xs={6} pl={0.5}>
                                    <Grid item xs={12} pb={0.5}>
                                        <Typography variant="p" fontWeight={500}>
                                            {t('clients.addPersonDialog.inputs.lastName')}*
                                        </Typography>
                                    </Grid>
                                    <TextField
                                        fullWidth
                                        value={data.lastName}
                                        onChange={event => setData(prevData => ({ ...prevData, lastName: event.target.value }))}
                                        placeholder="Popica"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} pt={1}>
                            <Grid container>
                                <Grid item xs={12} pb={0.5}>
                                    <Typography variant="p" fontWeight={500}>
                                        {t('clients.addPersonDialog.inputs.email')}
                                    </Typography>
                                </Grid>
                                <TextField
                                    fullWidth
                                    value={data.email}
                                    onChange={event => setData(prevData => ({ ...prevData, email: event.target.value }))}
                                    placeholder="someemail@domain.com"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} pt={1}>
                            <Grid container>
                                <Grid item xs={12} pb={0.5}>
                                    <Typography variant="p" fontWeight={500}>
                                        {t('clients.addPersonDialog.inputs.phoneNumber')}*
                                    </Typography>
                                </Grid>
                                <TextField
                                    fullWidth
                                    value={data.phoneNumber}
                                    onChange={event => setData(prevData => ({ ...prevData, phoneNumber: event.target.value }))}
                                    placeholder="+407XXXXXXXX"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} pt={1}>
                            <Grid container>
                                <Grid item xs={12} pb={0.5}>
                                    <Typography variant="p" fontWeight={500}>
                                        {t('clients.addPersonDialog.inputs.gender')}
                                    </Typography>
                                </Grid>
                                <FormControl>
                                    <RadioGroup
                                        defaultValue={data.gender}
                                        onChange={event => setData(prevData => ({
                                            ...prevData,
                                            gender: event.target.value
                                        }))}
                                    >
                                        <FormControlLabel value="MALE" control={<Radio />} label={t('settings.MALE')} />
                                        <FormControlLabel value="FEMALE" control={<Radio />} label={t('settings.FEMALE')} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} pt={1}>
                            <Grid container>
                                <Grid item xs={12} pb={0.5}>
                                    <Typography variant="p" fontWeight={500}>
                                        {t('clients.addPersonDialog.inputs.description')}
                                    </Typography>
                                </Grid>
                                <TextField
                                    rows={4}
                                    value={data.bio}
                                    multiline
                                    fullWidth
                                    onChange={event => setData(prevData => ({ ...prevData, bio: event.target.value }))}
                                    placeholder={t('clients.addPersonDialog.inputs.description')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} pr={{ xs: 0, md: 0, lg: 2 }}
                        pl={{ xs: 0, md: 0, lg: 2 }}>
                        <Grid container display="flex" flexDirection="row" spacing={3}>
                            <Grid item xs={12} md={12} lg={6}>
                                <Button onClick={onCancel} fullWidth variant="outlined">
                                    {t('clients.cancel')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <Button
                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                    fullWidth
                                    disabled={hasError()}
                                    onClick={() => {
                                        onCreateHandler()
                                    }}
                                    variant="contained"
                                >
                                    {t('clients.save')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}

export default AddPerson
