export const backgroundAssets = {
    1: require("../../../../../../../assets/challengeBackgrounds/1.png"),
    2: require("../../../../../../../assets/challengeBackgrounds/2.png"),
    3: require("../../../../../../../assets/challengeBackgrounds/3.png"),
    4: require("../../../../../../../assets/challengeBackgrounds/4.png"),
    5: require("../../../../../../../assets/challengeBackgrounds/5.png"),
    6: require("../../../../../../../assets/challengeBackgrounds/6.png"),
    7: require("../../../../../../../assets/challengeBackgrounds/7.png"),
    8: require("../../../../../../../assets/challengeBackgrounds/8.png"),
    9: require("../../../../../../../assets/challengeBackgrounds/9.png"),
    10: require("../../../../../../../assets/challengeBackgrounds/10.png"),
    11: require("../../../../../../../assets/challengeBackgrounds/11.png"),
    12: require("../../../../../../../assets/challengeBackgrounds/12.png"),
    13: require("../../../../../../../assets/challengeBackgrounds/13.jpg"),
    14: require("../../../../../../../assets/challengeBackgrounds/14.jpg"),
    15: require("../../../../../../../assets/challengeBackgrounds/15.jpg"),
    16: require("../../../../../../../assets/challengeBackgrounds/16.jpg"),
    17: require("../../../../../../../assets/challengeBackgrounds/17.jpg"),
    18: require("../../../../../../../assets/challengeBackgrounds/18.jpg"),
    19: require("../../../../../../../assets/challengeBackgrounds/19.jpg"),
    20: require("../../../../../../../assets/challengeBackgrounds/20.jpg"),
    21: require("../../../../../../../assets/challengeBackgrounds/21.jpg"),
    22: require("../../../../../../../assets/challengeBackgrounds/22.jpg"),
    23: require("../../../../../../../assets/challengeBackgrounds/23.jpg"),
  };