export const BoxComponentStyle = {
  position: 'relative',
  display: 'flex',
  height: '80px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '48px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  margin: '0 auto',
  marginBottom: '40px',
  width: '65%'
}

export const TextComponentStyle = {
  '& .MuiOutlinedInput-root': {
    display: 'flex',
    height: '70px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '48px',
    paddingLeft: '16px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    margin: '0 auto',
    marginBottom: '40px',
    width: '65%',
    position: 'absolute',
    bottom: '-70px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 110,

  }
}

export const LocationSearch = {
  width: '100%',
  marginRight: '15px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    paddingRight: '10px'
  }
}

export const ButtonSearchStyle = {
  backgroundColor: '#1a73e8',
  borderRadius: '48px',
  padding: '10px 20px',
  marginRight: '10px',
  textTransform: 'none'
}


export const SuggestionsListStyle = {
  position: 'absolute',
  backgroundColor: '#fff',
  zIndex: 10,
  width: '100%',
  maxHeight: '200px',
  overflowY: 'auto',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px'
}

export const SuggestionItemStyle = {
  padding: '10px 16px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f0f0f0'
  }
}