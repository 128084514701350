import { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import useServices from '../../../../../../hooks/useServices'
import { Box, useTheme } from '@mui/system'
import { Typography, useMediaQuery } from '@mui/material'
import CustomSlider from '../../../../../common/CustomSlider'
import ServiceCard from './subcomponents/ServiceCard'
import AllServices from './subcomponents/AllServices'
import { useExplore } from '../../../../../../context/explore-context'
import BoxElements from '../../../../../../utils/boxElementsV2'
import BoxContainer from '../../../../../../utils/boxContainer'

const ServiceSlider = ({ currentPosition }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const itemsPerRow = isMobile ? 1 : 4
  const [showAll, setShowAll] = useState(false)
  const { totalCount, services, getServices } = useServices()
  const { searchQuery, selectedRange } = useExplore()
  const titleRef = useRef(null)
  const fetchServices = useCallback(
    async (targetIndex) => {
      const newLimit = (targetIndex + 1) * itemsPerRow + itemsPerRow
      await getServices(0, newLimit, searchQuery, selectedRange, currentPosition)
    },
    [getServices, itemsPerRow, searchQuery, selectedRange, currentPosition]
  )

  useEffect(() => {
    if (showAll) return
    fetchServices(0)
  }, [fetchServices, showAll])

  const renderServiceCards = useMemo(() => {
    return services.map((service) => (
      <Box key={service.id} marginRight={!isMobile && 1} padding={!isMobile && '0 0 10px 10px'}>
        <ServiceCard service={service} />
      </Box>
    ))
  }, [services])

  return (
    <BoxContainer>
      {showAll && services.length > 0 ? (
        <AllServices services={services} setShowAll={setShowAll} showAll={showAll} />
      ) : (

        <BoxElements>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2, width: '100%' }}>
            <Typography
              ref={titleRef}
              variant="h5"
              fontWeight="bold"
              sx={{
                ml: 1,
                fontSize: isMobile ? '1rem' : '1.3rem',

              }}
              id="service-slider">
              Services
            </Typography>
            {services.length > 0 && (
              <Typography
                variant="h5"
                color="primary"
                sx={{
                  cursor: 'pointer', mr: 1,
                  fontSize: isMobile ? '1rem' : '1.3rem',

                }}
                onClick={() => setShowAll((prev) => !prev)}>
                Show All
              </Typography>
            )}
          </Box>

          {services.length > 0 ? (
            <CustomSlider totalCount={totalCount} itemsPerRow={itemsPerRow} fetch={fetchServices}>
              {renderServiceCards}
            </CustomSlider>
          ) : (
                <Typography sx={{ marginTop: isMobile ? '-40px' : '-50px', fontSize: isMobile ? '1rem' : '1.3rem', }} variant="h6" color="text.secondary">
              No services found
            </Typography>
          )}
        </BoxElements>
      )}
    </BoxContainer>
  )
}

export default ServiceSlider
