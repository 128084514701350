import React, { useState } from 'react'
import { TextField, Button, useMediaQuery } from '@mui/material'
import {
  ButtonSearchStyle,
  TextComponentStyle
} from './index.style'
import { useTheme } from '@emotion/react'
import { useExplore } from '../../../../../../../../context/explore-context'
import useLocationSearch from '../../../../../../../../hooks/useLocationSearch'


const SearchBar = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { searchLocation } = useLocationSearch()

  const { searchQuery, setSearchQuery } = useExplore()
  const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery)


  const handleSearchClick = () => {
    setSearchQuery(localSearchQuery)
    searchLocation(localSearchQuery)
  }

  return (

    <TextField
      id="search-input"
      value={localSearchQuery}
      onChange={(e) => setLocalSearchQuery(e.target.value)}
      variant="outlined"
      placeholder="Search inside sections"
      InputProps={{
        endAdornment: (

          <Button
            variant="contained"
            color="primary"
            sx={ButtonSearchStyle}
            onClick={handleSearchClick}>
            Search
          </Button>
        ),
        style: {
          backgroundColor: '#fff',

        },
        inputProps: {
          style: {
            paddingLeft: '20px',
          },
        }
      }}
      sx={{
        ...TextComponentStyle,
        ...(isMobile && {
          width: '100%',
          marginRight: 0,
        }),
      }}
    />
  )
}

export default SearchBar
