import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Sections, MapSection } from './subcomponents';
import ContainerV2 from '../../utils/containerExploreV2';
import { ExploreHeader } from './subcomponents/MapSection/subcomponents/CustomMap/subcomponents';
import Control from './subcomponents/Control/indes';
import { ChallengeSlider, Classes, ClubSlider, CourseSlider, ServiceSlider, TrainerSlider, ServiceBookSlider } from './subcomponents/Sections/subcomponents';
import { GOOGLE_API_KEY } from '../../utils/config';
import { useExplore } from '../../context/explore-context';
import { useLocation } from 'react-router-dom';
import { isValidCoordinate } from '../../utils/helpers/validator';

const ExploreV2 = () => {
  const [selectedTabs, setSelectedTabs] = useState([]);
  const { currentPosition, setCurrentPosition } = useExplore();

  useEffect(() => {
    const fetchGeolocation = async () => {
      if (!navigator.geolocation) {
        return;
      }

      const geoSuccess = async (position) => {
        const { latitude, longitude } = position.coords;

        if (isValidCoordinate(latitude) && isValidCoordinate(longitude)) {
          setCurrentPosition({ lat: latitude, lng: longitude });
        } else {
          console.error('Invalid geolocation coordinates:', { latitude, longitude });
        }
      };

      const geoErrorHandler = (error) => {
        console.error('Error obtaining location:', error);
      };

      navigator.geolocation.getCurrentPosition(geoSuccess, geoErrorHandler);
    };

    fetchGeolocation();
  }, [setCurrentPosition]);


  return (
    <Grid
      container
      width={'100%'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{ backgroundColor: '#f9f9f9' }}
    >
      <ContainerV2>
        <ExploreHeader />
      </ContainerV2>
      {selectedTabs.length === 0 ? (
        <ContainerV2>
          <MapSection currentPosition={currentPosition} />
          <Control selectedTabs={selectedTabs} setSelectedTabs={setSelectedTabs} />
          <ChallengeSlider />
          {/* <ServiceBookSlider currentPosition={currentPosition} /> */}
          <Classes currentPosition={currentPosition} />
          <ServiceSlider currentPosition={currentPosition} />
          <CourseSlider />
          <Sections />
          <ClubSlider currentPosition={currentPosition} />
          <TrainerSlider currentPosition={currentPosition} />
        </ContainerV2>
      ) : (
        <ContainerV2>
          <MapSection currentPosition={currentPosition} />
          <Control selectedTabs={selectedTabs} setSelectedTabs={setSelectedTabs} />
          {selectedTabs.includes('Challenges') && <ChallengeSlider />}
          {/* {selectedTabs.includes('Activities') && <ServiceBookSlider currentPosition={currentPosition} />} */}
          {selectedTabs.includes('Activities') && <Classes currentPosition={currentPosition} />}
          {selectedTabs.includes('Services') && <ServiceSlider currentPosition={currentPosition} />}
          {selectedTabs.includes('Courses') && <CourseSlider currentPosition={currentPosition} />}
          <Sections />
          <ClubSlider currentPosition={currentPosition} />
          <TrainerSlider currentPosition={currentPosition} />
        </ContainerV2>
      )}
    </Grid>
  );
};

export default ExploreV2;
