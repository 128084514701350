import React, { useState, useRef, useEffect } from 'react';
import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';

const CustomSlider = ({ totalCount, fetch, children }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const MAX_VISIBLE_DOTS = isMobile ? 6 : 12;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [slidesToShow, setSlidesToShow] = useState(1);
    const containerRef = useRef(null);

    const totalPages = Math.ceil(totalCount / slidesToShow);

    useEffect(() => {
        const updateSlidesToShow = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const childWidth = containerRef.current.firstChild?.firstChild?.offsetWidth || containerWidth;
                setSlidesToShow(Math.floor(containerWidth / childWidth));
            }
        };

        updateSlidesToShow();

        window.addEventListener('resize', updateSlidesToShow);
        return () => window.removeEventListener('resize', updateSlidesToShow);
    }, []);

    const getVisibleDots = () => {
        if (totalPages <= MAX_VISIBLE_DOTS) return Array.from({ length: totalPages }, (_, i) => i);
        if (currentIndex < Math.floor(MAX_VISIBLE_DOTS / 2)) {
            return Array.from({ length: MAX_VISIBLE_DOTS }, (_, i) => i);
        } else if (currentIndex >= totalPages - Math.ceil(MAX_VISIBLE_DOTS / 2)) {
            return Array.from({ length: MAX_VISIBLE_DOTS }, (_, i) => totalPages - MAX_VISIBLE_DOTS + i);
        }
        return Array.from({ length: MAX_VISIBLE_DOTS }, (_, i) => currentIndex - Math.floor(MAX_VISIBLE_DOTS / 2) + i);
    };

    const visibleDots = getVisibleDots();

    const scrollToIndex = (index) => {
        const containerWidth = containerRef.current.offsetWidth;
        containerRef.current.scrollTo({
            left: index * containerWidth,
            behavior: 'smooth',
        });
    };

    const loadItemsForIndex = async (index) => {
        setLoading(true);
        await fetch(index);
        setLoading(false);
    };

    useEffect(() => {
        loadItemsForIndex(currentIndex);
    }, []);

    const getVisibleItems = () => {
        const startIndex = currentIndex * slidesToShow;
        return React.Children.toArray(children).slice(startIndex, startIndex + slidesToShow);
    };

    return (
        <Box position="relative" mt={2} width={'100%'}>
            <Box
                ref={containerRef}
                display="flex"
                overflow="hidden"
                justifyContent="flex-start"
                width="100%"
                sx={{ scrollSnapType: 'x mandatory', scrollBehavior: 'smooth' }}
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    width="auto"
                    sx={{ transition: 'transform 0.5s ease' }}
                >
                    {getVisibleItems().map((child, index) => (
                        <Box
                            key={index}
                            sx={{
                                boxSizing: 'border-box',
                                flex: `0 0 ${100 / slidesToShow}%`,
                                maxWidth: `${100 / slidesToShow}%`,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '0 auto',
                            }}
                        >
                            {child}
                        </Box>
                    ))}
                </Box>
            </Box>

            {loading && (
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    zIndex={2}
                >
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};

export default CustomSlider;
