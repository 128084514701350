import React, { useEffect, useMemo, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { Grid, Box, Button, Fab, Typography, Fade, Tooltip, CircularProgress } from '@mui/material'
import { Forward, KeyboardArrowDown, KeyboardArrowUp, QrCodeScanner } from '@mui/icons-material'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga'
import io from 'socket.io-client'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { ProductFruits } from 'react-product-fruits'
import { OlvyWidget, OlvyUtils } from '@olvyhq/widget-react'
import { Userpilot } from 'userpilot'
import Login from './Login/Login'
import ResetPassword from './ResetPassword/ResetPassword'
import SignUp from './SignUp/SignUp'
import Dashboard from './Dashboard/Dashboard'
import { CreateNewPassword } from './ResetPassword/subcomponents'
import Navbar from './Navbar/Navbar'
import UserProfile from './UserProfile/UserProfile'
import TrainerProfile from './TrainerProfile/TrainerProfile'
import BusinessProfile from './BusinessProfile/BusinessProfile'
import Activities from './Activities/Activities'
import PublicUserProfile from './PublicUserProfile/PublicUserProfile'
import TrainerSettings from './TrainerSettings/TrainerSetting'
import BusinessSettings from './BusinessSettings/BusinessSettings'
import UserSettings from './UserSettings/UserSettings'
import UserMeasurements from './UserMeasurements/UserMeasurements'
import TrainerMeasurements from './TrainerMeasurements/TrainerMeasurements'
import BottomBar from './BottomBar/BottomBar'
import Domain from './Domain/Domain'
import ServiceDetails from './Services/Details/Details'
import Checkout from './Checkout/Checkout'
import Employees from './Employees/Employees'
import ClubCreate from './CreateClub/CreateClub'
import CheckInOut from './ChckInOut'
import Calendar from './Calendar'
import ClientsBusiness from './ClientsBusiness/ClientsBusiness'
import ClientsTrainer from './ClientsTrainer/ClientsTrainer'
import SelfIn from './Selfin'
import Reports from './Reports'
import SuperAdmin from './SuperAdmin/SuperAdmin'
import SuccessPayment from './SuccessPayment'
import RecurringPayments from './RecurringPayments'
import Payments from './Payments/Payments'
import Vouchers from './Vouchers/Vouchers'
import VoucherUsers from './VoucherUsers'
import Configurations from './Configurations'
import { EcreditsDashboard, EcreditsUsers, EcreditsAssociations } from './Ecredits'
import {
  Revenue,
  Trainers as DetailedReportsTrainers,
  Clients as DetailedReportsClients
} from './Reports/DetailedReports'
import PayRevo from './PayRevo'
import Payouts from './Payouts'
// import AuditLogs from './AuditLogs'
import AuditLogsV2 from './AuditLogsV2'
import ExploreV2 from './ExploreV2'

import { useAuthentication } from '../context/auth-context'
import { useError } from '../context/error-context'
import { Snackbar, CheckInsDialog, Footer } from './common'

import { ReactComponent as Logo } from '../assets/logo-simple.svg'
import {
  BUSINESS_ROLES,
  ROLE_BUSINESS_ADMINISTRATOR,
  ROLE_TRAINER,
  ROLE_USER,
  SUPER_ADMIN,
  ECREDITS_COMPANY_ADMIN_ROLE,
  ECREDIST_COMPANY_EMPLOYEE_ROLE,
  ROLE_ORGANIZATION
} from '../utils/constants'
import * as EMPLOYEE_REQUESTS from '../api/employees'
import * as RELAY_REQUESTS from '../api/accessControlRelays'
import * as COMPANY_REQUESTS from '../api/company'
import { switchRoleObservable } from '../utils/observables'
import { BASE_URL } from '../utils/config'
import SoundAlert from '../assets/welcome.mp4'
import * as relayHelpers from '../utils/helpers/relay'
import { CalendarService, CalendarServiceEdit } from './Calendar/subcomponents'
import CompanyCalendarServices from './CompanyCalendarServices/CompanyCalendarServices'
import DealsChallenges from './DealsChallenges'
import NewDeals from './NewDeals'
import Price from './Price'
import SignUpIndividualCompany from './SignUpIndividualCompany'
import OrganizationSettings from './OrganizationSettings'
import { LoadScript } from '@react-google-maps/api'
import ContainerV2 from '../utils/containerExploreV2'
import { ExploreHeader } from './ExploreV2/subcomponents/MapSection/subcomponents/CustomMap/subcomponents'

const useStyles = makeStyles({
  container: {
    maxWidth: '100%',
    height: '100vh'
  },
  logoContainer: {
    height: 60,
    padding: '24px 36px'
  }
})

const UNAUTH_STACK = [
  { path: '/', element: <ExploreV2 /> },
  { path: '/login', element: <Login /> },
  // { path: '/explore', element: <Explore /> },
  { path: '/signup/individual', element: <SignUpIndividualCompany typeSignUp={'user'} /> },
  // { path: '/signup/company', element: <SignUpIndividualCompany typeSignUp={'organization'} /> },
  { path: '/calendar/services/:ID', element: <CalendarService /> },
  { path: '/calendar/:domain', element: <Calendar /> },
  { path: '/calendar-services/company/:domain', element: <CompanyCalendarServices /> },
  { path: '/calendar-services/trainer/:domain', element: <CompanyCalendarServices /> },
  { path: '/reset-password', element: <ResetPassword /> },
  { path: '/change-password/:id', element: <CreateNewPassword /> },
  { path: '/athlete/:id', element: <PublicUserProfile /> },
  { path: '/services/:id', element: <ServiceDetails /> },
  { path: '/signup', element: <SignUp /> },
  { path: '/signup/:domain', element: <SignUp /> },
  { path: '/:domain', element: <Domain /> },
  { path: '*', element: <Login /> }
]

const ECREDITS_COMPANY_ADMIN_STACK = [
  { path: '/', element: <EcreditsDashboard /> },
  { path: '/associations', element: <EcreditsAssociations /> },
  { path: '/users', element: <EcreditsUsers /> },
  { path: '*', element: <EcreditsDashboard /> }
]

const ECREDITS_COMPANY_EMPLOYEE_STACK = [
  { path: '/', element: <EcreditsDashboard /> },
  { path: '/associations', element: <EcreditsAssociations /> },
  { path: '/users', element: <EcreditsUsers /> },
  { path: '*', element: <EcreditsDashboard /> }
]
const ORGANIZATION_STACK = [
  { path: '/', element: <DealsChallenges /> },
  { path: '/company-profile', element: <UserProfile /> },
  { path: '/settings', element: <OrganizationSettings /> },
  { path: '/activities', element: <Activities type="Organization" /> },
  { path: '/deals', element: <NewDeals type={'Organization'} /> }
]
const TRAINER_STACK = [
  { path: '/dashboard', element: <Dashboard /> },
  { path: '/deals', element: <NewDeals type={'Trainer'} /> },
  { path: '/price', element: <Price /> },
  { path: '/selfin/:domain', element: <SelfIn /> },
  { path: '/signup', element: <SignUp /> },
  // { path: '/explore', element: <Explore /> },
  { path: '/calendar', element: <Calendar /> },
  { path: '/calendar/services/:ID', element: <CalendarService /> },
  { path: '/calendar/services/:ID/edit', element: <CalendarServiceEdit /> },
  { path: '/calendar/:domain', element: <Calendar /> },
  { path: '/trainer-profile', element: <TrainerProfile /> },
  { path: '/clients', element: <ClientsTrainer /> },
  { path: '/athlete/:id', element: <PublicUserProfile /> },
  { path: '/settings', element: <TrainerSettings /> },
  { path: '/activities', element: <Activities type="Trainer" /> },
  { path: '/services/:id', element: <ServiceDetails /> },
  { path: '/measurements', element: <TrainerMeasurements /> },
  { path: '/:domain', element: <Domain /> },
  { path: '*', element: <TrainerProfile /> }
]

const USER_STACK = [
  { path: '/selfin/:domain', element: <SelfIn /> },
  { path: '/dashboard', element: <Dashboard /> },
  { path: '/checkout', element: <Checkout /> },
  { path: '/pay-card', element: <PayRevo /> },
  // { path: '/explore', element: <Explore /> },
  { path: '/signup', element: <SignUp /> },
  { path: '/calendar', element: <Calendar /> },
  { path: '/calendar/services/:ID', element: <CalendarService /> },
  { path: '/calendar/:domain', element: <Calendar /> },
  { path: '/calendar-services/company/:domain', element: <CompanyCalendarServices /> },
  { path: '/calendar-services/trainer/:domain', element: <CompanyCalendarServices /> },
  { path: '/user-profile', element: <UserProfile /> },
  { path: '/athlete/:id', element: <PublicUserProfile /> },
  { path: '/settings', element: <UserSettings /> },
  { path: '/measurements', element: <UserMeasurements /> },
  { path: '/services/:id', element: <ServiceDetails /> },
  { path: '/payments', element: <Payments /> },
  { path: '/success-payment/company/:companyId', element: <SuccessPayment /> },
  { path: '/recurring-payments', element: <RecurringPayments /> },
  { path: '/:domain', element: <Domain /> },
  { path: '*', element: <UserProfile /> }
]

const BUSINESS_STACK = [
  { path: '/dashboard', element: <Dashboard /> },
  { path: '/deals', element: <NewDeals type={'Company'} /> },
  { path: '/price', element: <Price /> },
  { path: '/configurations', element: <Configurations /> },
  // { path: '/explore', element: <Explore /> },
  { path: '/calendar', element: <Calendar /> },
  { path: '/calendar/services/:ID/edit', element: <CalendarServiceEdit /> },
  { path: '/calendar/:domain', element: <Calendar /> },
  { path: '/employees', element: <Employees /> },
  { path: '/checkin', element: <CheckInOut /> },
  { path: '/athlete/:id', element: <PublicUserProfile /> },
  { path: '/business-profile', element: <BusinessProfile /> },
  { path: '/activities', element: <Activities type="Company" /> },
  { path: '/business-create', element: <ClubCreate /> },
  { path: '/settings', element: <BusinessSettings /> },
  { path: '/clients', element: <ClientsBusiness /> },
  { path: '/reports', element: <Reports /> },
  { path: '/reports/revenue', element: <Revenue /> },
  { path: '/reports/trainers', element: <DetailedReportsTrainers /> },
  { path: '/reports/clients', element: <DetailedReportsClients /> },
  { path: '/payments', element: <Payments /> },
  { path: '/payouts', element: <Payouts /> },
  // { path: '/audit-logs', element: <AuditLogs /> },
  { path: '/audit-logs', element: <AuditLogsV2 /> },
  { path: '/vouchers', element: <Vouchers /> },
  { path: '/voucher-users', element: <VoucherUsers /> },
  { path: '/services/:id', element: <ServiceDetails /> },
  { path: '/:domain', element: <Domain /> },
  { path: '*', element: <BusinessProfile /> }
]

const BUSINESS_EMPLOYEE_STACK = [
  { path: '/', element: <Dashboard /> },
  { path: '/dashboard', element: <Dashboard /> },
  { path: '/deals', element: <NewDeals type={'Company'} /> },
  { path: '/reports', element: <Reports /> },
  { path: '/activities', element: <Activities type="Company" /> },
  { path: '/reports/revenue', element: <Revenue /> },
  // { path: '/explore', element: <Explore /> },
  { path: '/clients', element: <ClientsBusiness /> },
  { path: '/calendar', element: <Calendar /> },
  { path: '/calendar/:domain', element: <Calendar /> },
  { path: '/checkout', element: <Checkout /> },
  { path: '/checkin', element: <CheckInOut /> },
  { path: '/athlete/:id', element: <PublicUserProfile /> },
  { path: '/business-profile', element: <BusinessProfile /> },
  { path: '/vouchers', element: <Vouchers /> },
  { path: '/services/:id', element: <ServiceDetails /> },
  { path: '/:domain', element: <Domain /> },
  { path: '*', element: <BusinessProfile /> }
]

const SUPER_ADMIN_STACK = [{ path: '/', element: <SuperAdmin /> }]

const Router = () => {
  const classes = useStyles()
  const { isLogged, handleLoggedIn, handleToken, handleUser, user, token } = useAuthentication()
  const switchRole = switchRoleObservable.useSwitchCompany()
  const { error } = useError()
  const location = useLocation()
  const { i18n } = useTranslation()

  const [isLoginPage, setIsLoginPage] = useState(false)
  const [isExplorePage, setIsExplorePage] = useState(false)
  const [isRegisterPage, setIsRegisterPage] = useState(false)
  const [isResetPasswordPage, setIsResetPasswordPage] = useState(false)
  const [isChangePasswordPage, setIsChangePasswordPage] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [socket, setSocket] = useState()
  const [checkIns, setCheckIns] = useState([])
  const [relays, setRelays] = useState([])
  const [showMoreRelays, setShowMoreRelays] = useState(false)
  const [configuration, setConfiguration] = useState()
  const [turnistleSetupLoading, setTurnstileSetupLoading] = useState(false)

  const navigate = useNavigate()

  // Sending Userpilot informations
  useEffect(() => {
    if (isLogged) {
      const userPilotData = {
        name: user?.companyName ? user.companyName : `${user?.firstName} ${user?.lastName}`,
        email: user?.email,
        role: user?.role,
      };

      Userpilot.identify(
        user?.id,
        userPilotData
      )
    }
  }, [isLogged])

  useEffect(() => {
    if (!user || !user.companyId) return

    RELAY_REQUESTS.getAll().then((data) => {
      setRelays(data)
    })

    COMPANY_REQUESTS.getConfigurations().then(setConfiguration)
  }, [user])

  useEffect(() => {
    if (!socket || !user || !user.companyId || !configuration) return

    const checkInAlertHandler = ({ companyId, ...restOfData }) => {
      if (Number(companyId) === Number(user.companyId)) {
        const welcomeAudio = new Audio(SoundAlert)
        welcomeAudio.play()
        setCheckIns((prevCheckIns) => prevCheckIns.concat([restOfData]))

        if (restOfData.failed) {
          toast.error(
            `Eroare la Selfin pentru clientul ${restOfData.user ? `${restOfData.user.firstName} ${restOfData.user.lastName}` : ''
            }`,
            { position: 'bottom-right', autoClose: false }
          )
        } else {
          toast.success(
            `${restOfData.user ? `${restOfData.user.firstName} ${restOfData.user.lastName}` : ''
            } a facut SelfIn cu success`,
            { position: 'bottom-right', autoClose: true }
          )
        }
      }
    }

    if (configuration.wlecomeGoodbySoundOn) {
      socket.on('checkInAlert', checkInAlertHandler)
    }

    const openRelayHandler = ({ companyId, localInUrl, localOutUrl }) => {
      if (Number(companyId) === Number(user.companyId)) {
        relayHelpers
          .open(localInUrl, localOutUrl)
          .then(() => {
            toast.warning('Turnichete deschis', { position: 'bottom-right', autoClose: true })
          })
          .catch(() => {
            toast.error('Eroare deschidere turnichete, va rugam deschideti-l din aplicatie', {
              position: 'bottom-right',
              autoClose: true
            })
          })
      }
    }

    socket.on('openRelay', openRelayHandler)

    return () => {
      socket.off('connect')
      socket.off('disconnect', () => setSocket(null))
      socket.off('checkInAlert', checkInAlertHandler)
      socket.off('openRelay', openRelayHandler)
    }
  }, [socket, user, configuration])

  useEffect(() => {
    let bootstrapAsync = async () => {
      const token = localStorage.getItem('token')

      if (!token) {
        handleLoggedIn(false)
        handleToken('')
        return
      }

      const tokenDecoded = jwt_decode(token)

      handleUser(tokenDecoded)
      handleLoggedIn(true)
      handleToken(token)
      let userLanguage = 'en'
      const userLanguageFromLocalStorage = localStorage.getItem('newLanguage')
      if (userLanguageFromLocalStorage) {
        userLanguage = userLanguageFromLocalStorage
      } else
        userLanguage =
          tokenDecoded && tokenDecoded.role === 'ORGANIZATION'
            ? tokenDecoded.locale === 'ENG'
              ? 'en'
              : tokenDecoded.locale.toLowerCase()
            : tokenDecoded && tokenDecoded.language
              ? tokenDecoded.language === 'ENG'
                ? 'en'
                : tokenDecoded.language.toLowerCase()
              : tokenDecoded.user && tokenDecoded.user.language
                ? tokenDecoded.user.language === 'EN'
                  ? 'en'
                  : tokenDecoded.user.language.toLowerCase()
                : 'en'
      i18n.changeLanguage(userLanguage)
    }

    bootstrapAsync()
  }, [])

  useEffect(() => {
    if (!user || !user.companyId || !token) return

    try {
      const socketInstance = io(BASE_URL, {
        query: { token }
      })
      setSocket(socketInstance)
    } catch (e) {
      toast.error('Fail to establish connection.Please refresh and re-login.', {
        position: 'bottom-right'
      })
    }
  }, [user, token])

  useEffect(() => {
    if (switchRole && switchRole.companyId) {
      EMPLOYEE_REQUESTS.switchRole(switchRole.companyId).then((response) => {
        handleLoggedIn(true)
        handleToken(response.token)
        handleUser(response.user)
        localStorage.setItem('token', response.token)

        if (switchRole.navigate) {
          navigate(switchRole.navigate)
        }

        switchRoleObservable.setSwitchCompanyRole(null)
      })
    }
  }, [switchRole])

  useEffect(() => {
    const currentHostname = window.location.hostname

    const replaceRoute = (stack, path, newElement) => {
      const routeIndex = stack.findIndex((route) => route.path === path)
      if (routeIndex !== -1) {
        stack[routeIndex] = newElement
      } else {
        stack.push(newElement)
      }
    }

    // if (currentHostname === 'activities.sportin.io') {
    //   // Set Explore as homepage and Login for Individuals only
    //   replaceRoute(USER_STACK, '/', { path: '/', element: <ExploreV2 /> })
    //   replaceRoute(UNAUTH_STACK, '/', { path: '/', element: <ExploreV2 /> })
    // }

    // if (currentHostname === 'app.sportin.io') {
    //   // No homepage, only login for Professionals
    //   replaceRoute(USER_STACK, '/', { path: '/', element: <Login /> })
    //   replaceRoute(UNAUTH_STACK, '/', { path: '/', element: <Login /> })
    // }
  }, [])

  useEffect(() => {
    ReactGA.pageview(location.pathname)
    if (location && location.pathname && location.pathname.includes('login')) {
      setIsLoginPage(true)
    } else setIsLoginPage(false)
    if (
      (location && location.pathname && location.pathname.includes('explore')) ||
      location.pathname === '/' ||
      location.pathname === ''
    ) {
      setIsExplorePage(true)
    } else {
      setIsExplorePage(false)
    }

    if (location && location.pathname && location.pathname.includes('signup')) {
      setIsRegisterPage(true)
    } else {
      setIsRegisterPage(false)
    }
    if (location && location.pathname && location.pathname.includes('reset-password')) {
      setIsResetPasswordPage(true)
    } else {
      setIsResetPasswordPage(false)
    }

    if (location && location.pathname && location.pathname.includes('change-password')) {
      setIsChangePasswordPage(true)
    } else {
      setIsChangePasswordPage(false)
    }

    setIsChecked(true)
  }, [location, isLogged, isExplorePage])

  const handleOpenMenu = () => {
    setIsMenuOpen((prevValue) => !prevValue)
  }

  const renderCheckInsDialog = useMemo(() => {
    if (!checkIns.length || !configuration) return null
    if (!configuration.detailedSelfinOn) {
      return null
    }

    return <CheckInsDialog data={checkIns} onClose={() => setCheckIns([])} />
  }, [checkIns, configuration])

  const renderRelayControl = useMemo(() => {
    if (!relays || !relays.length || !user || !user.companyId) return

    const initializeTurnstileHandler = async () => {
      setTurnstileSetupLoading(true)

      try {
        for (let i = 0; i < relays.length; i++) {
          const relay = relays[i]
          await relayHelpers.open(relay.localInUrl, relay.localOutUrl)
          await new Promise((resolve) => setTimeout(resolve, 1000))
        }
      } catch (error) {
        toast.error('A aparut o eroare la initalizare', {
          position: 'bottom-right',
          autoClose: true
        })
      }
      setTurnstileSetupLoading(false)
      toast.success('Initializare facuta cu success', { position: 'bottom-right', autoClose: true })
    }

    return (
      <>
        <Fab
          color="primary"
          variant="circular"
          onClick={() => {
            setShowMoreRelays((prevValue) => !prevValue)
          }}
          sx={{
            position: 'fixed',
            bottom: (theme) => theme.spacing(8),
            left: (theme) => theme.spacing(2)
          }}>
          {showMoreRelays ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </Fab>
        <Fade in={showMoreRelays} timeout={300}>
          <Tooltip title="Initializare">
            <Fab
              color="warning"
              variant="extended"
              sx={{
                position: 'fixed',
                bottom: (theme) => theme.spacing(9 + 7),
                left: (theme) => theme.spacing(2)
              }}
              onClick={initializeTurnstileHandler}>
              {turnistleSetupLoading ? (
                <CircularProgress size="1.5rem" sx={{ color: 'white' }} />
              ) : (
                <QrCodeScanner />
              )}
            </Fab>
          </Tooltip>
        </Fade>
        {relays.map((relay, index) => {
          return (
            <Fade in={showMoreRelays} timeout={300}>
              <Fab
                color="primary"
                variant="extended"
                sx={{
                  position: 'fixed',
                  bottom: (theme) => theme.spacing(9 + 7 * (index + 2)),
                  left: (theme) => theme.spacing(2)
                }}
                onClick={() =>
                  relayHelpers
                    .open(relay.localInUrl, relay.localOutUrl)
                    .then(() => {
                      toast.warning('Turnichete deschis', {
                        position: 'bottom-right',
                        autoClose: true
                      })
                    })
                    .catch(() => {
                      toast.error(
                        'Eroare deschidere turnichete, va rugam deschideti-l din aplicatie',
                        { position: 'bottom-right', autoClose: true }
                      )
                    })
                }>
                <Typography pl={1} pr={1} variant="p" fontWeight="bold">
                  {relay.name}
                </Typography>
                <Forward />
              </Fab>
            </Fade>
          )
        })}
      </>
    )
  }, [relays, showMoreRelays, user, turnistleSetupLoading])

  const renderHeader = useMemo(() => {
    if (!isLogged && isLoginPage) return null
    if (!isLogged && !isLoginPage) {
      if (isExplorePage) return null
      return (
        <Grid
          sx={{ display: 'flex', flexDirection: 'row' }}
          container
          height={100}
          paddingTop={4}
          paddingBottom={4}
          paddingLeft={3}
          paddingRight={{ xs: 2, lg: 6 }}>
          <Grid>
            <Logo onClick={() => navigate('/')} height={56} width={65} />
          </Grid>
          {!isRegisterPage && !isResetPasswordPage && !isChangePasswordPage && (
            <Grid
              sx={{
                flexDirection: 'row',
                display: 'flex',
                marginLeft: 'auto'
              }}>
              <Grid item pr={1}>
                <Button
                  onClick={() => navigate('/login')}
                  fullWidth
                  color="primary"
                  variant="outlined">
                  Log In
                </Button>
              </Grid>
              <Grid item width={{ lg: 150, xs: 120 }}>
                <Button
                  fullWidth
                  onClick={() => navigate('/signup')}
                  color="primary"
                  variant="contained">
                  Sign Up
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      )
    }
    if (
      isLogged &&
      user.role.toLowerCase() !== ECREDITS_COMPANY_ADMIN_ROLE.toLocaleLowerCase() &&
      user.role.toLocaleLowerCase() !== ECREDIST_COMPANY_EMPLOYEE_ROLE.toLocaleLowerCase()
    ) {
      return (
        <Grid
          container
          flexDirection="row"
          height={{ lg: 95, xs: 60 }}
          paddingTop={{ xs: 2, lg: 4 }}
          paddingBottom={{ xs: 0.5, lg: 4 }}
          paddingLeft={{ xs: 0.5, lg: 6 }}
          paddingRight={{ xs: 0.5, lg: 6 }}>
          <Grid sx={{ cursor: 'pointer' }} display={{ xs: 'none', lg: 'flex' }} item lg={1}>
            <Logo onClick={() => navigate('/')} height={56} width={65} />
          </Grid>
          <Grid item xs={12} lg={11}>
            <Navbar handleOpenMenu={handleOpenMenu} isMenuOpen={isMenuOpen} />
          </Grid>
        </Grid>
      )
    }
  }, [
    isLogged,
    user,
    isLoginPage,
    isMenuOpen,
    isExplorePage,
    isChangePasswordPage,
    isRegisterPage,
    isResetPasswordPage
  ])

  if (!isChecked) return null

  return (
    <>
      {process.env.NODE_ENV !== 'development' && (
        <ProductFruits
          workspaceCode="UkvzDlEKbh8vBQ9U"
          language="en"
          user={{
            role: user ? user.role : 'not_logged',
            email: user ? user.email : 'not_logged',
            firstname: user ? user.firstName : 'not_logged',
            lastname: user ? user.lastName : 'not_logged',
            username: user ? `${user.firstName} ${user.lastName}` : 'not_logged'
          }}
        />
      )}

      {renderCheckInsDialog}
      <Box className={classes.container}>
        {renderRelayControl}
        {error && <Snackbar message={error} />}
        <Box height={{ xs: 'calc(100% - 55px)' }} width="100vw" overflow={'auto'}>
          {!isLogged && !isExplorePage ?
            <Grid
              container
              width={'100%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{ backgroundColor: '#fff' }}
            >
              <ContainerV2>
                <ExploreHeader backgroundColor={"#fff"} />
              </ContainerV2>
            </Grid> :
            <>
              {renderHeader}
            </>}

          <Routes>
            {!isLogged &&
              UNAUTH_STACK.map((el, index) => {
                return <Route key={index} {...el} />
              })}
            {isLogged &&
              user &&
              user.role.toLowerCase() === ROLE_TRAINER.toLowerCase() &&
              TRAINER_STACK.map((el, index) => {
                return <Route key={index} {...el} />
              })}
            {isLogged &&
              user &&
              user.role.toLowerCase() === ROLE_ORGANIZATION.toLowerCase() &&
              ORGANIZATION_STACK.map((el, index) => {
                return <Route key={index} {...el} />
              })}
            {isLogged &&
              user &&
              user.role.toLowerCase() === ROLE_USER.toLowerCase() &&
              USER_STACK.map((el, index) => {
                return <Route key={index} {...el} />
              })}
            {isLogged &&
              user &&
              user.role.toLowerCase() === ROLE_BUSINESS_ADMINISTRATOR.toLowerCase() &&
              BUSINESS_STACK.map((el, index) => {
                return <Route key={index} {...el} />
              })}
            {isLogged &&
              user &&
              user.role.toLowerCase() === BUSINESS_ROLES.BUSINESS_EMPLOYEE.toLowerCase() &&
              BUSINESS_EMPLOYEE_STACK.map((el, index) => {
                return <Route key={index} {...el} />
              })}
            {isLogged &&
              user &&
              user.role.toLowerCase() === SUPER_ADMIN.toLowerCase() &&
              SUPER_ADMIN_STACK.map((el, index) => {
                return <Route key={index} {...el} />
              })}
            {isLogged &&
              user &&
              user.role.toLowerCase() === ECREDIST_COMPANY_EMPLOYEE_ROLE.toLowerCase() &&
              ECREDITS_COMPANY_EMPLOYEE_STACK.map((el, index) => {
                return <Route key={index} {...el} />
              })}
            {isLogged &&
              user &&
              user.role.toLowerCase() === ECREDITS_COMPANY_ADMIN_ROLE.toLowerCase() &&
              ECREDITS_COMPANY_ADMIN_STACK.map((el, index) => {
                return <Route key={index} {...el} />
              })}
          </Routes>
        </Box>
        <Footer />
        <BottomBar handleOpenMenu={handleOpenMenu} />

        <OlvyWidget config={{ workspaceAlias: 'sportin' }} targetElement=""></OlvyWidget>
      </Box>
    </>
  )
}

export default Router
