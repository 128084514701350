import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography, Button, TextField, Avatar, Box, IconButton, Menu, MenuItem, Divider, ListItemIcon, ListItemText } from '@mui/material'
import { MoreVert, Check, Close, Edit } from '@mui/icons-material'

import { Footer, Table, SimplePagination } from '../../common'
import AddPerson from './AddPerson'

import * as COMPANY_REQUESTS from '../../../api/company'

const PAGE_SIZE = 35

export default () => {
    const { t } = useTranslation()

    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [usersTotalCount, setUsersTotalCount] = useState(0)
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState()
    const [anchorElement, setAnchorElement] = useState()
    const [showAddClient, setShowAddClient] = useState(false)

    const getUsers = useCallback(() => {
        return COMPANY_REQUESTS.getOwnUsers({ from: (page - 1) * PAGE_SIZE, limit: PAGE_SIZE, search })
            .then(res => {
                setUsers(res.rows)
                setUsersTotalCount(res.count)
            })
    }, [page, search])

    useEffect(() => {
        getUsers()
    }, [page, search])
    const date = (data) => {
        const formattedDate = new Date(data);

        const year = formattedDate.getFullYear();
        const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
        const day = String(formattedDate.getDate()).padStart(2, '0');
        const hours = String(formattedDate.getHours()).padStart(2, '0');
        const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
        return `${day}/${month}/${year} - ${hours}:${minutes}`;
    }

    return (
        <>
            {showAddClient ? <AddPerson
                user={selectedUser}
                onConfirm={() => getUsers()}
                onCancel={() => setShowAddClient(false)}
            /> : null}
            <Grid container pt={3} pb={3}>
                <Grid item pb={5} xs={12}>
                    <Grid container flexDirection="row" display="flex">
                        <Grid item xs={12} md={12} lg={6} display="flex" flexDirection="column" justifyContent="center">
                            <Typography variant="h5" fontWeight="bold">
                                {t('clients.ownClients')}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={12}
                            lg={6}
                            justifyContent="flex-end"
                            flexDirection="row"
                            display="flex"
                        >
                            <Grid
                                container
                                display="flex"
                                flexDirection="row"
                                spacing={3}
                                justifyContent="flex-end"
                            >
                                <Grid item xs={12} md={12} lg={3} display="flex" flexDirection="column" justifyContent="flex-end">
                                    <Button fullWidth variant="contained" onClick={() => setShowAddClient(true)}>
                                        + {t('clients.client')}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={12} lg={3} display="flex" flexDirection="column" justifyContent="center">
                                    <Typography variant="p" pb={0.5}>{t('clients.search')}</Typography>
                                    <TextField
                                        onChange={event => setSearch(event.target.value)}
                                        fullWidth
                                        value={search}
                                        placeholder={t('clients.searchClient')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} pb={5}>
                    <Table
                        dense
                        headCells={[
                            t('clients.tableHeadUser.avatar'),
                            t('clients.tableHeadUser.fullName'),
                            t('clients.tableHeadUser.phoneNumber'),
                            t('clients.tableHeadUser.gender'),
                            t('clients.tableHeadUser.email'),
                            t('clients.tableHeadUser.createdAt'),
                            t('clients.tableHeadUser.noPhone'),
                            t('clients.tableHeadUser.actions'),
                        ]}
                        rows={users.map((user) => {

                            return {
                                avatar: (
                                    <Avatar src={user.avatarPhotoSrc}>{user.lastName[0]}</Avatar>
                                ),
                                fullName: (
                                    <Typography
                                        variant="subtitle1"
                                        color={user.isDeleted ? "error" : "primary"}
                                    >
                                        {`${user.firstName} ${user.lastName}`}
                                        <Typography variant='subtitle1'>
                                            {user.isDeleted && "(Deleted)"}
                                        </Typography>
                                    </Typography>
                                ),
                                phoneNumber: (
                                    <Typography
                                        variant="subtitle1"
                                    >
                                        {`${user.phoneNumber?.split(".deleted.")?.[0] || user.phoneNumber}`}
                                    </Typography>
                                ),
                                gender: (
                                    <Typography
                                        variant="subtitle1"
                                    >
                                        {`${user.gender}`}
                                    </Typography>
                                ),
                                email: (
                                    <Typography
                                        variant="subtitle1"
                                    >
                                        {user.email.includes('.deleted.') ? `${user.email.split(".deleted.")[0]}@${user.email.split("@")[1]}` : user.email}
                                    </Typography>
                                ),
                                createdAt: (
                                    <Typography
                                        variant="subtitle1"
                                    >
                                        {date(user.createdAt)}
                                    </Typography>
                                ),
                                noPhone: user.withoutSmartPhone ? <Close /> : <Check />,
                                actiuni: user.belongingCompanyId ? <Box>
                                    <IconButton onClick={event => {
                                        setAnchorElement(event.currentTarget);
                                        setSelectedUser(user)
                                    }}>
                                        <MoreVert />
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorElement}
                                        open={selectedUser && selectedUser.id === user.id}
                                        onClose={() => {
                                            setAnchorElement(null)
                                            setSelectedUser()
                                        }}
                                    >
                                        <MenuItem disabled>
                                            {t('clients.tableHead.actions')}
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem disabled={user.email.includes('.deleted.')} onClick={() => {
                                            setSelectedUser(user)
                                            setShowAddClient(true)
                                        }}>
                                            <ListItemIcon>
                                                <Edit fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>{t('button.edit')}</ListItemText>
                                        </MenuItem>
                                    </Menu>
                                </Box> : null
                            }
                        })}
                    />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <SimplePagination
                        count={Math.ceil(Number(usersTotalCount / PAGE_SIZE))}
                        onPageChange={setPage}
                    />
                </Grid>
            </Grid>
        </>
    )
}