import { useCallback, useState } from 'react'
import { getChallangesReq } from '../api/explore'

const useChallenges = () => {
  const [challenges, setChallenges] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0);

  const getChallenges = useCallback(async (from = 0, limit = 5, search) => {
    setLoading(true)
    try {
      const res = await getChallangesReq(from, limit, search)
      setChallenges(res.challenges)
      setTotalCount(res.count)
    } catch (error) {
      console.error('Error fetching challenges', error)
    } finally {
      setLoading(false)
    }
  }, [])

  return { challenges, loading, getChallenges, totalCount }
}

export default useChallenges
