import React, { useState } from 'react'
import { Box, Typography, IconButton, Dialog } from '@mui/material'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ReactPlayer from 'react-player'

const LessonsDialog = ({ Lessons }) => {
  const [open, setOpen] = useState(false)
  const [selectedLesson, setSelectedLesson] = useState(null)

  const handleOpen = (lesson) => {
    setSelectedLesson(lesson)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedLesson(null)
  }

  return (
    <Box sx={{ overflowX: 'hidden', height: '400px' }}>
      <Box
        sx={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}>
        <Typography variant="h5">Overview</Typography>
        <Typography variant="h5" marginRight={'20px'} color="textSecondary">
          {Lessons.lessons.length} Lessons
        </Typography>
      </Box>

      <Box>
        {Lessons.lessons.map((lesson, index) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background: '#F8F8F8',
              padding: '10px 15px',
              margin: '10px 0',
              borderRadius: '8px',
              cursor: 'pointer'
            }}
            onClick={() => handleOpen(lesson)}>
            <Box display="flex" alignItems="center">
              <PlayCircleIcon sx={{ color: 'black', marginRight: '10px' }} />
              <Typography variant="body1">{lesson.nameLeson}</Typography>
            </Box>

            <IconButton>
              <VisibilityIcon sx={{ color: 'blue' }} />
            </IconButton>
          </Box>
        ))}
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-labelledby="video-dialog-title"
        aria-describedby="video-dialog-description">
        <Box sx={{ padding: '20px' }}>
          {selectedLesson && (
            <>
              <Typography variant="h4" id="video-dialog-title">
                {selectedLesson.nameLeson}
              </Typography>
              <Box display="flex" justifyContent="center" mt={2}>
                <ReactPlayer url={selectedLesson.linkLesson} width="100%" controls />
              </Box>
            </>
          )}
        </Box>
      </Dialog>
    </Box>
  )
}

export default LessonsDialog
