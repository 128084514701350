import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'


const Sections = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingX: '5px', marginTop: '30px', marginBottom: '-20px' }}>
      <Typography fontSize={isMobile ? 30 : 44} textAlign={'center'}>Looking for a proffesional or a gym near you?</Typography>
    </Box>
  )
}

export default Sections
