export const CardStyle = {
  borderRadius: '15px',
  boxShadow: `-4px -4px 12px rgba(0, 0, 0, 0.03), 4px 4px 12px rgba(0, 0, 0, 0.03)`,
  overflow: 'hidden',
  marginTop: '10px',
  height: '280px',
  width: '350px',
  display: 'flex',
  flexDirection: 'column'
}

export const BorderBox = {
  position: 'relative',
  width: '100%',
  height: '150px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '5px',
  boxSizing: 'border-box',
  backgroundColor: '#fff',
  borderTopLeftRadius: '15px',
  borderTopRightRadius: '15px'
}

export const ImageBox = {
  width: '100%',
  height: '100%',
  borderRadius: '10px',
  objectFit: 'cover'
}

export const OverlayBox = {
  position: 'absolute',
  top: '5px',
  left: '5px',
  width: 'calc(100% - 10px)',
  height: 'calc(100% - 10px)',
  borderRadius: '10px',
  backgroundColor: 'rgba(0, 0, 0, 0.5)'
}

export const priceStyles = {
  textTransform: 'none',
  padding: '4px 12px'
}

export const subtextStyles = {
  left: {
    color: '#555'
  },
  right: {
    color: '#555'
  }
}

export const titleStyles = {
  color: '#333',
  fontWeight: 'bold'
}

export const UndefinedImage = {
  width: '50px',
  height: '50px',
  backgroundColor: '#ccc',
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '10px',
  fontSize: '20px',
  fontWeight: 'bold',
  borderRadius: '50%'
}
