import { Box, useMediaQuery } from "@mui/material"
import theme from "./mui/globalMui";

const BoxElements = ({ children }) => {
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{
                position: 'relative',
                backgroundColor: '#fff',
                width: isSmallScreen ? '100%' : '90%',
                height: 'auto',
                maxHeight: '100%',
                marginTop: '10px',
                borderRadius: '30px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '20px',
                boxSizing: 'border-box'
            }}>
            {children}
        </Box>

    )
}
export default BoxElements