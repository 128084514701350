import React, { useEffect, useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'
import Gleap from 'gleap';

import { Type, TypeOrgaization, TypeCompany, TypeTrainer, Welcome } from './subcomponents'
import { LANGUAGE_RO, ROLE_BUSINESS_ADMINISTRATOR, ROLE_TRAINER, ROLE_USER, TYPE_ORG, TYPE_COMPANY, TYPE_TRAINER } from '../../utils/constants'

import * as AUTH_REQUESTS from '../../api/auth'
import * as USER_ERQUESTS from '../../api/user'
import { useAuthentication } from '../../context/auth-context'
import { useError } from '../../context/error-context'

const SignUp = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { handleError } = useError()
  const { handleLoggedIn, handleToken, handleUser } = useAuthentication()
  const { domain } = useParams()

  const [searchParams, setSearchParams] = useSearchParams()
  const [step, setStep] = useState()
  const [type, setType] = useState('')
  const [register, setRegister] = useState()
  const [sending, setSending] = useState(false)
  const [error, setError] = useState()

  useEffect(() => {
    if (searchParams.get('type')) {
      const typeValue = searchParams.get('type')
      if (typeValue.toLowerCase() === 'club') {
        setStep(2)
        setType(t(`type.${TYPE_COMPANY.toLowerCase()}`).toLowerCase())
      }
    }
    else if (searchParams.get('serviceId') || searchParams.get('eventId')) {
      setStep(2)
      setType(t(`type.${TYPE_ORG.toLowerCase()}`))
    }
  }, [searchParams, t])

  useEffect(() => {
    if (!domain) {
      setStep(1)  
      return
    }
    
    const lowerCaseDomain = domain.toLowerCase();
    const lowerCaseTrainer = t(`type.${TYPE_TRAINER.toLowerCase()}`).toLowerCase();
    const lowerCaseCompany = t(`type.${TYPE_COMPANY.toLowerCase()}`).toLowerCase();
    const lowerCaseOrganization = t(`type.${TYPE_ORG.toLowerCase()}`).toLowerCase();

    const roleType = lowerCaseDomain === lowerCaseTrainer ? lowerCaseTrainer : lowerCaseDomain === lowerCaseCompany ? lowerCaseCompany : lowerCaseDomain === lowerCaseOrganization ? lowerCaseOrganization : '';

    if (!roleType) {
      setStep(1)
      return
    }

    setStep(2)
    setType(roleType)
  }, [domain])

  const handleOnSelect = (newType) => setType(newType)

  const handleNext = () => {
    if (step === 1) navigate(`/signup/${type}`)
    else setStep(prevValue => prevValue + 1)
  }

  const handlePrev = () => {
    if (step === 1) navigate('/login')
    else {
      navigate('/signup')
    }
  }

  const handleSubmit = async (data, isCompany) => {
    try {
      handleError('')
      setSending(true)
      const { domain, ...restOfData } = data
      const registerData = await AUTH_REQUESTS.register(type.toLowerCase() === t(`type.${TYPE_ORG.toLowerCase()}`).toLowerCase() ? restOfData : data, isCompany === 'organization' ? 'organization' : isCompany ? 'company' : 'user')
      setSending(false)
      setRegister({ ...registerData, password: isCompany ? data.user.password : data.password })
      if (searchParams.get('serviceId') || searchParams.get('eventId')) {
        handleDashboard(LANGUAGE_RO, { ...registerData, password: isCompany ? data.user.password : data.password })
      }
      else {
        handleNext()
      }
      ReactGA.event({
        category: 'PRE_LOGIN',
        action: 'SIGN_UP',
        label: 'SUCCESS'
      })
    } catch (e) {
      setSending(false)
      handleError(t('signup.error'))
      setError(
        e && e.response && e.response.data
          ? e.response.data.split('Error: ')[1].split('\n')[0]
          : 'register_error'
      )
      ReactGA.event({
        category: 'PRE_LOGIN',
        action: 'SIGN_UP',
        label: 'ERROR'
      })
      console.error(e)
    }
  }

  const handleDashboard = async (selectedLanguage, registerData) => {
    try {
      const { user } = register ? register : registerData
      setSending(true)
      const loginData = await AUTH_REQUESTS.login(user.email, register ? register.password : registerData.password)
      setSending(false)
      handleLoggedIn(true)
      handleToken(loginData.token)
      handleUser(loginData.user)
      localStorage.setItem('token', loginData.token)
      await USER_ERQUESTS.updateLanguage(loginData.user.id, selectedLanguage)
      i18n.changeLanguage(selectedLanguage.toLowerCase() === 'eng' ? 'en' : selectedLanguage.toLowerCase())

      Gleap.identify(`${user.id}`, {
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        phone: user.phoneNumber,
        language: user.language,
        ...(user.role === "BUSINESS_ADMINISTRATOR" ? { companyName: user.companyName, companyId: user.companyId } : {}),
      });

      if (searchParams.get('serviceId')) {
        let queryParams = `?successRegister=true&`
        const keys = ['selectedSlot', 'selectedCost', 'selectedPaymentMethod', 'day', 'serviceId', 'selectedSubscription']
        keys.forEach(key => {
          queryParams += `${key}=${searchParams.get(key)}&`
        })
        navigate(`/calendar/services/${searchParams.get('serviceId')}${queryParams}`)
      }
      else if (searchParams.get('eventId')) {
        navigate(`/calendar?eventId=${searchParams.get('eventId')}`)
      }
      else {
        if (loginData.user.role === ROLE_USER) navigate('/user-profile')
        else if (loginData.user.role === ROLE_TRAINER) navigate('/trainer-profile')
        else if (loginData.user.role === ROLE_BUSINESS_ADMINISTRATOR) navigate('/business-profile')
        else navigate('/')
      }
    } catch (e) {
      setSending(false)
      console.error(e)
    }
  }

  const handleLogin = (language) => {
    navigate(`/login?lang=${language}`)
  }

  const renderType = useMemo(() => {
    return (
      <Type
        handleNext={handleNext}
        handlePrev={handlePrev}
        type={type}
        handleOnSelect={handleOnSelect}
      />
    )
  }, [type, handleOnSelect, handleNext, handlePrev])

  const renderTypeForm = useMemo(() => {
    if (type.toLowerCase() === t(`type.${TYPE_ORG.toLowerCase()}`).toLowerCase())
      return (
        <TypeOrgaization
          error={error}
          sending={sending}
          handlePrev={handlePrev}
          handleSubmit={handleSubmit}
        />
      )
    else if (type.toLowerCase() === t(`type.${TYPE_TRAINER.toLowerCase()}`).toLowerCase())
      return (
        <TypeTrainer
          error={error}
          sending={sending}
          handlePrev={handlePrev}
          handleSubmit={handleSubmit}
        />
      )
    else if (type.toLowerCase() === t(`type.${TYPE_COMPANY.toLowerCase()}`).toLowerCase())
      return (
        <TypeCompany
          error={error}
          sending={sending}
          handlePrev={handlePrev}
          handleSubmit={handleSubmit}
        />
      )
  }, [type, step, sending, error])

  const renderWelcome = useMemo(() => {
    return <Welcome sending={sending} handleDashboard={handleDashboard} handleLogin={handleLogin} />
  }, [handleDashboard, handleLogin, sending])

  const renderContent = useMemo(() => {
    if (step === 1) return renderType
    else if (step === 2) return renderTypeForm
    else if (step === 3) return renderWelcome
  }, [step, error, type, renderType, renderTypeForm])

  return (
    <Grid paddingTop={4} container height="100%" spacing={2}>
      {renderContent}
    </Grid>
  )
}

export default SignUp
