import { Box, Chip, Grid, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import useActivityTypes from '../../../../hooks/useActivityTypes'
import { useExplore } from '../../../../context/explore-context'
import { getSportTypeFromName } from '../../../../utils/helpers/formatSportType'
import { CHIP_BLUE_COLOR } from '../../../../utils/constants'
import BoxElements from '../../../../utils/boxElementsV2'

const Control = ({ selectedTabs, setSelectedTabs }) => {
  const theme = useTheme()
  const { activityTypes, getActivityTypes } = useActivityTypes()
  const { selectedActivities, setSelectedActivities } = useExplore()
  const [paginationText, setPaginationText] = useState('Show All')

  const fetchActivities = useCallback(
    async (limit) => {
      await getActivityTypes(0, limit)
    },
    [getActivityTypes]
  )

  useEffect(() => {
    fetchActivities(20)
  }, [fetchActivities])

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleCardClick = (activityName) => {
    setSelectedActivities((prevSelectedActivities) => {
      const sportType = getSportTypeFromName(activityName)
      return prevSelectedActivities.includes(sportType)
        ? prevSelectedActivities.filter((name) => name !== sportType)
        : [...prevSelectedActivities, sportType]
    })
  }

  const handlePagination = async () => {
    if (paginationText === 'Show All') {
      await fetchActivities(100)
      setPaginationText('Show Less')
    } else {
      await fetchActivities(20)
      setPaginationText('Show All')
    }
  }

  const renderActivityCards = useCallback(() => {
    return activityTypes.map((activity) => {
      const isSelected = selectedActivities.includes(getSportTypeFromName(activity.name))
      const isDisabled = activity.activitiesCount === 0

      return (
        <Grid
          key={activity.name}
          onClick={() => {
            if (!isDisabled) handleCardClick(activity.name)
          }}
          item
          sx={{ cursor: isDisabled ? 'default' : 'pointer' }}>
          <Chip
            sx={{
              backgroundColor: isDisabled
                ? theme.palette.grey
                : isSelected
                ? theme.palette.primary.main
                : CHIP_BLUE_COLOR,
              color: isDisabled
                ? theme.palette.text.disabled
                : isSelected
                ? '#fff'
                : theme.palette.primary.main
            }}
            label={`${activity.name}   ${activity.activitiesCount}`}
          />
        </Grid>
      )
    })
  }, [activityTypes, selectedActivities, handleCardClick, theme])

  const handleTabClick = (tabValue) => {
    setSelectedTabs((prevSelectedTabs) => {
      if (prevSelectedTabs.includes(tabValue)) {
        return prevSelectedTabs.filter((value) => value !== tabValue)
      } else {
        return [...prevSelectedTabs, tabValue]
      }
    })
  }

  return (
    <Box sx={styles.container}>
      <BoxElements>
        <Typography
          variant="h5"
          sx={[
            styles.header,
            {
              fontSize: isSmallScreen ? '1rem' : '1.3rem'
            }
          ]}>
          Discover your next adventure!
        </Typography>

        <Box sx={styles.tabsContainer}>
          <Tabs
            aria-label="wrapped label tabs example"
            variant="fullWidth"
            scrollButtons="auto"
            sx={{
              width: '100%',
              '& .MuiTab-root': {
                fontFamily: 'Lato, Medium',
                fontSize: isSmallScreen ? '0.8rem' : '1rem',
                minWidth: isSmallScreen ? '80px' : '120px',
                padding: isSmallScreen ? '6px 8px' : '10px 16px'
              },
              '& .Mui-selected': {
                color: theme.palette.primary.main,
                borderBottom: `2px solid ${theme.palette.primary.main}`
              },
              '& .MuiTabs-scrollButtons': {
                '&.Mui-disabled': { opacity: 0.3 }
              }
            }}>
            {['Challenges', 'Activities', 'Services', 'Courses'].map((tabValue, index) => (
              <Tab
                key={`${tabValue}-${index}`}
                value={tabValue}
                label={tabValue}
                sx={{
                  textTransform: 'capitalize',
                  color: selectedTabs.includes(tabValue) ? theme.palette.primary.main : '#000',
                  borderBottom: selectedTabs.includes(tabValue)
                    ? `2px solid ${theme.palette.primary.main}`
                    : `2px solid #dfdfdf`
                }}
                onClick={() => handleTabClick(tabValue)}
              />
            ))}
          </Tabs>
        </Box>
        <Box sx={styles.activityCardsBox}>
          <Grid container spacing={0.5} sx={styles.activityCardsContainer}>
            {renderActivityCards()}
          </Grid>
        </Box>
        <Typography
          variant="h5"
          color="primary"
          sx={{
            ...styles.paginationText,
            fontSize: isSmallScreen ? '0.8rem' : '1rem'
          }}
          onClick={handlePagination}>
          {paginationText}
        </Typography>
      </BoxElements>
    </Box>
  )
}

export default Control

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    marginTop: '50px'
  },
  header: {
    marginBottom: '25px',
    fontWeight: 'bold'
  },
  tabsContainer: {
    width: { xs: '90%', sm: '70%' },
    paddingBottom: '5px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center'
  },
  activityCardsBox: {
    mt: '50px',
    mb: '10px',
    width: { xs: '90%', sm: '80%' }
  },
  activityCardsContainer: {
    pt: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  paginationText: {
    cursor: 'pointer',
    mb: '-15px',
    mt: '30px'
  }
}
