import React, { useEffect, useContext } from 'react'
import { CircularProgress, Box } from '@mui/material'
import { GoogleMapsContext } from '../../../../context/google-context'
import CustomMap from './subcomponents/CustomMap'

const MapSection = () => {
  const { isLoaded, loadError } = useContext(GoogleMapsContext)

  if (loadError) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    )
  }

  if (
    !isLoaded
  ) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <CustomMap />
  )
}

export default MapSection
