import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState, useCallback, useMemo } from 'react'
import CustomSlider from '../../../../../common/CustomSlider'
import { Card } from './subcomponents'
import useClasses from '../../../../../../hooks/useClasses'
import AllCards from './subcomponents/AllCards'
import { useExplore } from '../../../../../../context/explore-context'
import BoxElements from '../../../../../../utils/boxElementsV2'
import BoxContainer from '../../../../../../utils/boxContainer'

const Classes = ({ currentPosition }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const itemsPerRow = isMobile ? 1 : 4
  const [showAll, setShowAll] = useState(false)
  const { totalCount, classes, getClasses } = useClasses()
  const { selectedActivities, searchQuery } = useExplore()

  const fetchClasses = useCallback(
    async (targetIndex) => {
      const newLimit = (targetIndex + 1) * itemsPerRow + itemsPerRow

      await getClasses(0, newLimit, selectedActivities, searchQuery, currentPosition.latitude, currentPosition.longitude)
    },
    [getClasses, itemsPerRow, selectedActivities, searchQuery, currentPosition]
  )

  useEffect(() => {
    if (showAll) return
    fetchClasses(0)
  }, [fetchClasses, showAll, currentPosition])

  const renderClassCards = useMemo(() => {
    return classes.map((item) => (
      <Box key={item.id} marginRight={!isMobile && 1} padding={!isMobile && '0 0 10px 10px'}>
        <Card item={item} />
      </Box>
    ))
  }, [classes])

  return (
    <BoxContainer>
      {showAll && classes.length > 0 ? (
        <AllCards showAll={showAll} setShowAll={setShowAll} classes={classes} />
      ) : (

        <BoxElements>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{
            mb: 2, width: '100%',


          }}>
            <Typography variant="h5" fontWeight="bold" sx={{
              ml: 1,
              fontSize: isMobile ? '1rem' : '1.3rem',

            }}>
              Classes
            </Typography>
            {classes.length > 0 && (
              <Typography

                variant="h5"
                color="primary"
                sx={{
                  cursor: 'pointer', mr: 1,
                  fontSize: isMobile ? '1rem' : '1.3rem',

                }}
                onClick={() => setShowAll((prev) => !prev)}>
                Show All
              </Typography>
            )}
          </Box>
          {classes.length > 0 ? (
            <CustomSlider totalCount={totalCount} itemsPerRow={itemsPerRow} fetch={fetchClasses}>
              {renderClassCards}
            </CustomSlider>
          ) : (
            <Typography sx={{ marginTop: isMobile ? '-40px' : '-50px', fontSize: isMobile ? '1rem' : '1.3rem', }} variant="h6" color="text.secondary">
              No classes found
            </Typography>
          )}
        </BoxElements>
      )}
    </BoxContainer>

  )
}

export default Classes
