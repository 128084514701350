import { Box, Modal, Button, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useState } from 'react';

const ITEMS_PER_PAGE = 12;

const ShowAllModal = ({ isShowAll, setIsShowAll, gap, children, totalCount, fetch, currentItemsCount }) => {
  const [loading, setLoading] = useState(false);

  const handleShowMore = async () => {
    setLoading(true);
    await fetch('more');
    setLoading(false);
  };

  const handleShowLess = async () => {
    setLoading(true);
    await fetch('less');
    setLoading(false);
  };

  return (
    <Modal open={isShowAll} onClose={() => setIsShowAll(false)} style={{ overflow: 'hidden' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          backgroundColor: "#f9f9f9",
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: '10%',
            paddingLeft: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 50,
              height: 50,
              borderRadius: '50%',
              backgroundColor: 'black',
              cursor: 'pointer',
            }}
            onClick={() => setIsShowAll(false)}
          >
            <ArrowBackIcon sx={{ color: '#fff' }} />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            paddingLeft: '30px',
            gap: gap || 5,
            width: '100%',
            height: '80%',
            overflowY: 'auto',
          }}
        >
          {children}
        </Box>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <CircularProgress />
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '10%',
            padding: 2,
          }}
        >
          {!loading && (
            <>
              {currentItemsCount > ITEMS_PER_PAGE && (
                <Button variant="contained" onClick={handleShowLess}>
                  Show Less
                </Button>
              )}
              {currentItemsCount < totalCount && (
                <Button variant="contained" onClick={handleShowMore} sx={{ marginLeft: 2 }}>
                  Show More
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ShowAllModal;
