import React, { useState } from 'react'
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Button
} from '@mui/material'
import SearchBar from './subcomponents/Filters/SearchBar'
import ExploreImage from '../../../../../../assets/ExploreImage.png'

const CustomMap = () => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [geoError, setGeoError] = useState(null)


  const handleLocationIconClick = (position) => {
    console.log('Location icon clicked with position:', position)
  }

  if (geoError) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        width="100%">
        <Typography variant="h6" color="error" gutterBottom>
          {geoError}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords
                console.log('Retry user location:', { lat: latitude, lng: longitude })
                setGeoError(null)
              },
              (error) => {
                console.error('Error obtaining location on retry:', error)
                setGeoError('Unable to retrieve your location.')
              }
            )
          }}>
          Retry
        </Button>
      </Box>
    )
  }

  return (
    <Box
      width={'100%'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
      }}>
      <Box
        sx={{
          position: 'relative',
          width: isSmallScreen ? '100%' : '90%',
          height: isSmallScreen ? '300px' : 'calc(90vh - 200px)',
          maxHeight: '400px',
          margin: 'auto',
          marginTop: '10px',
          borderRadius: '30px',
          overflow: 'hidden'
        }}>
        <img
          src={ExploreImage}
          alt="Placeholder Map"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'top', // Acesta este modificarea pentru a afișa partea de sus
            borderRadius: '30px'
          }}
        />
      </Box>
      <SearchBar
        onLocationIconClick={handleLocationIconClick}
      />
    </Box>
  )
}

export default CustomMap
