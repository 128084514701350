import { CALENDAR_SERVICES_TYPES, SPORTTYPES_TYPES } from '../constants'

export const getBackgroundImage = (option) => {
    if (!option || option === CALENDAR_SERVICES_TYPES.OTHERS) {
        return require('../../assets/club_bg.jpeg')
    }
    if (option === SPORTTYPES_TYPES.MEDITATION) {
        return require('../../assets/sportTypes/Meditation.webp')
    }
    if (option === SPORTTYPES_TYPES.POSTURAL_ALIGNMENT) {
        return require('../../assets/sportTypes/postural.webp')
    }
    if (option === SPORTTYPES_TYPES.HYPOXI_VACUUM_FITNESS) {
        return require('../../assets/sportTypes/HypoxiVacuumFitness.jpg')
    }
    if (option === SPORTTYPES_TYPES.ACTIVE_RECOVERY) {
        return require('../../assets/sportTypes/ActiveRecovery.webp')
    }
    if (option === SPORTTYPES_TYPES.BALET) {
        return require('../../assets/sportTypes/Balet.webp')
    }
    if (option === SPORTTYPES_TYPES.AEROBIC) {
        return require('../../assets/sportTypes/Aerobic-min.webp')
    }
    if (option === SPORTTYPES_TYPES.AEROBIC_STEP) {
        return require('../../assets/sportTypes/Aerobic-Step.webp')
    }
    if (option === SPORTTYPES_TYPES.ANIMAL) {
        return require('../../assets/sportTypes/animal.webp')
    }
    if (option === SPORTTYPES_TYPES.PREGNANT_TRAINING) {
        return require('../../assets/sportTypes/PregnantTraining.webp')
    }
    if (option === SPORTTYPES_TYPES.STRENGTH_CONDITION) {
        return require('../../assets/sportTypes/StrenghtAndCondition.webp')
    }
    if (option === SPORTTYPES_TYPES.TRAIL_RUNNING) {
        return require('../../assets/sportTypes/TrailRunning.webp')
    }
    if (option === SPORTTYPES_TYPES.YOGA) {
        return require('../../assets/sportTypes/Yoga-min.webp')
    }
    if (option === SPORTTYPES_TYPES.PILATES) {
        return require('../../assets/sportTypes/Pilates-min.webp')
    }
    if (option === SPORTTYPES_TYPES.CIRCUIT_TRAINING) {
        return require('../../assets/sportTypes/CircuitTraining-min.webp')
    }
    if (option === SPORTTYPES_TYPES.BOXING) {
        return require('../../assets/sportTypes/Boxing.webp')
    }
    if (option === SPORTTYPES_TYPES.KICKBOXING) {
        return require('../../assets/sportTypes/Kickboxing-min.webp')
    }
    if (option === SPORTTYPES_TYPES.MARTIAL_ARTS) {
        return require('../../assets/sportTypes/martialArt.webp')
    }
    if (option === SPORTTYPES_TYPES.POLE_FITNESS) {
        return require('../../assets/sportTypes/PoleFitness.webp')
    }
    if (option === SPORTTYPES_TYPES.BODYBUILDING) {
        return require('../../assets/sportTypes/Bodybuilding-min.webp')
    }
    if (option === SPORTTYPES_TYPES.INTERVAL_TRAINING) {
        return require('../../assets/sportTypes/IntervalTraining-min.webp')
    }
    if (option === SPORTTYPES_TYPES.WEIGHT_TRAINING) {
        return require('../../assets/sportTypes/WeightTraining-min.webp')
    }
    if (option === SPORTTYPES_TYPES.CROSSFIT) {
        return require('../../assets/sportTypes/Crossfit-min.webp')
    }
    if (option === SPORTTYPES_TYPES.AERIAL_FITNESS) {
        return require('../../assets/sportTypes/AerialYoga.webp')
    }
    if (option === SPORTTYPES_TYPES.GYMNASTICS) {
        return require('../../assets/sportTypes/Gymnastics-min.webp')
    }
    if (option === SPORTTYPES_TYPES.SPARTAN_RACE) {
        return require('../../assets/sportTypes/SpartanRage.webp')
    }
    if (option === SPORTTYPES_TYPES.FOOTBALL) {
        return require('../../assets/sportTypes/fb.webp')
    }
    if (option === SPORTTYPES_TYPES.TENNIS) {
        return require('../../assets/sportTypes/Tenis.webp')
    }
    if (option === SPORTTYPES_TYPES.RUNNING) {
        return require('../../assets/sportTypes/Running-min.webp')
    }
    if (option === SPORTTYPES_TYPES.PHYSIOTHERAPY) {
        return require('../../assets/sportTypes/Physiotherapy-min.webp')
    }
    if (option === SPORTTYPES_TYPES.KINESIOTHERAPY) {
        return require('../../assets/sportTypes/Kinesiotherapy-min.webp')
    }
    if (option === SPORTTYPES_TYPES.PERSONAL_TRAINING) {
        return require('../../assets/sportTypes/PersonalTraining-min.webp')
    }
    if (option === SPORTTYPES_TYPES.MULTILEVEL_GROUP_TRAINING) {
        return require('../../assets/sportTypes/MultilevelGroupTraining-min.webp')
    }
    if (option === SPORTTYPES_TYPES.BOOTCAMP) {
        return require('../../assets/sportTypes/Bootcamp-min.webp')
    }
    if (option === SPORTTYPES_TYPES.ZUMBA) {
        return require('../../assets/sportTypes/Zumba.webp')
    }
    if (option === SPORTTYPES_TYPES.DANCE) {
        return require('../../assets/sportTypes/Dance-min.webp')
    }
    if (option === SPORTTYPES_TYPES.WORKSHOP) {
        return require('../../assets/sportTypes/workshop.tt.webp')
    }
    if (option === SPORTTYPES_TYPES.NUTRITION) {
        return require('../../assets/sportTypes/Nutrition.webp')
    }
    if (option === SPORTTYPES_TYPES.INDOOR_CYCLING || option === SPORTTYPES_TYPES.HOME_CYCLING) {
        return require('../../assets/sportTypes/cycling.webp')
    }
    if (option === SPORTTYPES_TYPES.INDOOR_CLIMB) {
        return require('../../assets/sportTypes/IndoorClimbing.webp')
    }
    if (option === SPORTTYPES_TYPES.KANGOO_JUMP) {
        return require('../../assets/sportTypes/kangoo.webp')
    }
    if (option === SPORTTYPES_TYPES.EMS_TRAINING) {
        return require('../../assets/sportTypes/EMSFitness-min.webp')
    }
    if (option === SPORTTYPES_TYPES.CALISTHENICS) {
        return require('../../assets/sportTypes/Calisthenics-min.webp')
    }
    if (option === SPORTTYPES_TYPES.CYCLING) {
        return require('../../assets/sportTypes/CyclingOutdoor-min.webp')
    }
    if (option === SPORTTYPES_TYPES.HIKING) {
        return require('../../assets/sportTypes/Hiking.webp')
    }
    if (option === SPORTTYPES_TYPES.HORSE_RIDING) {
        return require('../../assets/sportTypes/HorseRiding-min.webp')
    }
    if (option === SPORTTYPES_TYPES.HOME_CYCLING) {
        return require('../../assets/sportTypes/cycling.webp')
    }
    if (option === SPORTTYPES_TYPES.VOLLEYBALL) {
        return require('../../assets/sportTypes/Voleyball.webp')
    }
    if (option === SPORTTYPES_TYPES.SWIMMING) {
        return require('../../assets/sportTypes/swimming.webp')
    }
    if (option === SPORTTYPES_TYPES.SUSPENSION_TRAINING) {
        return require('../../assets/sportTypes/SuspensionTraining.webp')
    }
    if (option === CALENDAR_SERVICES_TYPES.FOTBAL) {
        return require('../../assets/sportTypes/fb.webp')
    }
    if (option === CALENDAR_SERVICES_TYPES.HIKING) {
        return require('../../assets/sportTypes/Hiking.webp')
    }
    if (option === CALENDAR_SERVICES_TYPES.KARTING) {
        return require('../../assets/sportTypes/Karting.webp')
    }
    if (option === CALENDAR_SERVICES_TYPES.MASSAGE) {
        return require('../../assets/sportTypes/Massage-min.webp')
    }
    if (option === CALENDAR_SERVICES_TYPES.ONLINE) {
        return require('../../assets/sportTypes/OnlineTraining.webp')
    }
    if (option === CALENDAR_SERVICES_TYPES.PAINTBALL) {
        return require('../../assets/sportTypes/paintball.webp')
    }
    if (option === CALENDAR_SERVICES_TYPES.SQUASH) {
        return require('../../assets/sportTypes/Squash.webp')
    }
    if (option === CALENDAR_SERVICES_TYPES.BASKETBALL) {
        return require('../../assets/sportTypes/Basketball.webp')
    }
    if (option === CALENDAR_SERVICES_TYPES.PADEL) {
        return require('../../assets/sportTypes/Padel.webp')
    }
    if (option === CALENDAR_SERVICES_TYPES.SNOOKER) {
        return require('../../assets/sportTypes/Snooker.webp')
    }
    if (option === CALENDAR_SERVICES_TYPES.BOWLING) {
        return require('../../assets/sportTypes/Bowling.webp')
    }
    if (option === CALENDAR_SERVICES_TYPES.TENNIS_CLAY) {
        return require('../../assets/sportTypes/Tennis-min.webp')
    }
    if (option === CALENDAR_SERVICES_TYPES.TENNIS_GRASS) {
        return require('../../assets/sportTypes/TennisCourt.webp')
    }
    if (option === CALENDAR_SERVICES_TYPES.TENNIS_TABLE) {
        return require('../../assets/sportTypes/TennisTable.webp')
    }
    if (option === CALENDAR_SERVICES_TYPES.DIETICIAN) {
        return require('../../assets/sportTypes/Dietician-min.webp')
    }
    if (option === CALENDAR_SERVICES_TYPES.HORSE) {
        return require('../../assets/sportTypes/HorseRidding.webp')
    }
    if (option === CALENDAR_SERVICES_TYPES.PHYSIO) {
        return require('../../assets/sportTypes/Physiotherapist-min.webp')
    }
    if (option === CALENDAR_SERVICES_TYPES.SHOOTING) {
        return require('../../assets/sportTypes/ShootingRange-min.webp')
    }

    return require('../../assets/club_bg.jpeg')
}